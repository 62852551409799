"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = void 0;
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
const transformNode_1 = require("@babylonjs/core/Meshes/transformNode");
const EntityAnimator_1 = require("./Entity/EntityAnimator");
const EntityMove_1 = require("./Entity/EntityMove");
const EntityNamePlate_1 = require("./Entity/EntityNamePlate");
const EntityMesh_1 = require("./Entity/EntityMesh");
const types_1 = require("../../shared/types");
class Entity extends transformNode_1.TransformNode {
    constructor(name, scene, gamescene, entity) {
        var _a;
        super(name, scene);
        // character
        this.type = "";
        this.race = "";
        this.material = 0;
        this.name = "";
        this.speed = "";
        this.location = "";
        this.anim_state = types_1.EntityState.IDLE;
        this.ai_state = 0;
        this.isDead = false;
        this.isDeadUI = false;
        this.isMoving = false;
        this.spawn_id = "";
        //
        this.abilities = [];
        this.inventory = [];
        this.equipment = [];
        // flags
        this.blocked = false; // if true, player will not moved
        // setup class variables
        this._scene = scene;
        this._room = gamescene.room;
        this._gamescene = gamescene;
        this._game = gamescene._game;
        this._navMesh = gamescene._navMesh;
        this._ui = gamescene._ui;
        this._shadow = gamescene._shadow;
        this.sessionId = entity.sessionId; // network id from colyseus
        this.isCurrentPlayer = this._room.sessionId === entity.sessionId;
        this.entity = entity;
        this._currentPlayer = gamescene._currentPlayer;
        this.type = "entity";
        // update player data from server data
        let race = this._game.getGameData("race", entity.race);
        this.raceData = race;
        Object.assign(this, race);
        // set entity
        Object.assign(this, this.entity);
        // get spawnInfo
        if (entity.type === "entity" && this._game.currentLocation.dynamic.spawns) {
            this.spawnInfo = (_a = this._game.currentLocation.dynamic.spawns[this.spawn_id]) !== null && _a !== void 0 ? _a : null;
        }
        // get material
        this.debugMaterialActive = this._scene.getMaterialByName("debug_entity_active");
        this.debugMaterialNeutral = this._scene.getMaterialByName("debug_entity_neutral");
        // spawn player
        this._game._vatController.prepareMesh(entity);
        // wait for vat to be ready
        setTimeout(() => {
            this.spawn(entity);
        }, 500);
    }
    spawn(entity) {
        return __awaiter(this, void 0, void 0, function* () {
            // set default vat animation
            this.entityData = this._game._vatController.entityData.get(this.vat.key);
            // load mesh controllers
            this.meshController = new EntityMesh_1.EntityMesh(this);
            yield this.meshController.load();
            this.mesh = this.meshController.mesh;
            this.debugMesh = this.meshController.debugMesh;
            this.selectedMesh = this.meshController.selectedMesh;
            this.playerSkeleton = this.meshController.skeleton;
            // set initial position & roation
            this.position = new math_vector_1.Vector3(entity.x, entity.y, entity.z);
            this.rotation = new math_vector_1.Vector3(0, entity.rot, 0);
            // add all entity related stuff
            this.animatorController = new EntityAnimator_1.EntityAnimator(this);
            this.moveController = new EntityMove_1.EntityMove(this);
            this.moveController.setPositionAndRotation(entity); // set next default position from server entity
            this.nameplateController = new EntityNamePlate_1.EntityNamePlate(this);
            ///////////////////////////////////////////////////////////
            // entity network event
            // colyseus automatically sends entity updates, so let's listen to those changes
            this.entity.onChange(() => {
                // make sure players are always visible
                this.mesh.isVisible = true;
                // if taking damage, show damage bubble
                if (this.health !== this.entity.health) {
                    this.nameplateController.addDamageBubble(0.2, this._ui._DebugBox.ping);
                }
                if (this.type === "player" && this.anim_state !== this.entity.anim_state) {
                    console.log("[SERVER] anim_state state has changed ", types_1.EntityState[this.entity.anim_state]);
                }
                // update player data from server data
                Object.assign(this, this.entity);
                // update player position
                this.moveController.setPositionAndRotation(this.entity);
                // do server reconciliation on client if current player only & not blocked
                if (this.isCurrentPlayer && !this.blocked) {
                    this.moveController.reconcileMove(this.entity.sequence); // set default entity position
                }
            });
            //////////////////////////////////////////////////////////////////////////
            // misc
            this.nameplate = this.nameplateController.addNamePlate();
        });
    }
    update(delta) {
        // choose entity animation state
        if (this.animatorController) {
            this.animatorController.animate(this);
        }
        // move update
        if (this && this.moveController) {
            this.moveController.update();
        }
        // animate player continuously
        if (this.animatorController) {
            // refresh animation ratio
            this.animatorController.refreshAnimationRatio();
            // animate player continuously
            this.animatorController.play(this);
        }
        // update nameplate
        if (this.nameplateController) {
            this.nameplateController.update();
        }
        // if entity is selected, show
        if (this.selectedMesh && this.selectedMesh.visibility) {
            if (this._game.selectedEntity && this._game.selectedEntity.sessionId === this.sessionId) {
                this.selectedMesh.isVisible = true;
                this.selectedMesh.rotate(new math_vector_1.Vector3(0, 0.1, 0), 0.01);
            }
            else {
                this.selectedMesh.isVisible = false;
            }
        }
    }
    updateServerRate(delta) { }
    updateSlowRate(delta) { }
    getPosition() {
        return new math_vector_1.Vector3(this.position.x, this.position.y, this.position.z);
    }
    // basic performance LOD logic
    lod(_currentPlayer) {
        if (!this.mesh) {
            return false;
        }
        // only enable if close enough to local player
        let entityPos = this.getPosition();
        let playerPos = _currentPlayer.getPosition();
        let distanceFromPlayer = math_vector_1.Vector3.Distance(playerPos, entityPos);
        if (distanceFromPlayer < this._game.config.PLAYER_VIEW_DISTANCE) {
            this.setEnabled(true);
            //this.unfreezeWorldMatrix();
        }
        else {
            // hide everything
            this.setEnabled(false);
            //this.freezeWorldMatrix();
        }
    }
    remove() {
        // delete any ui linked to entity
        //this.characterLabel.dispose();
        //this.characterChatLabel.dispose();
        if (this.interactableButtons) {
            this.interactableButtons.dispose();
        }
        // remove nameplate
        this.nameplate.dispose();
        // delete mesh, including equipment
        this.meshController.deleteMeshes();
        // delete any action manager
        if (this.meshController.mesh.actionManager) {
            this.meshController.mesh.actionManager.dispose();
            this.meshController.mesh.actionManager = null;
        }
        // if was selected, make sure to unselect it
        if (this._game.selectedEntity && this._game.selectedEntity.sessionId === this.sessionId) {
            this._game.selectedEntity = false;
        }
        // remove selected mesh
        this.meshController.selectedMesh.isVisible = false;
    }
}
exports.Entity = Entity;
